<template>
  <div>
    <b-button
      v-if="showToday"
      size="xs"
      class="mr-2 text-uppercase"
      variant="outline-secondary"
      @click="setDatepickerFiltersToToday"
      >{{ $t('datetime.day.today') }}</b-button
    >
    <b-button
      v-if="showYesterday"
      size="xs"
      class="mr-2 text-uppercase"
      variant="outline-secondary"
      @click="setDatepickerFiltersToYesterday"
      >{{ $t('datetime.day.yesterday') }}</b-button
    >
    <b-button
      v-if="showCurrentWeek"
      size="xs"
      class="mr-2 text-uppercase"
      variant="outline-secondary"
      @click="setDatepickerFiltersToCurrentWeek"
      >{{ $t('datetime.week.current-short') }}</b-button
    >
    <b-button
      v-if="showLastWeek"
      size="xs"
      class="mr-2 text-uppercase"
      variant="outline-secondary"
      @click="setDatepickerFiltersToLastWeek"
      >{{ $t('datetime.week.last-short') }}</b-button
    >
    <b-button
      v-if="showCurrentMonth"
      size="xs"
      class="mr-2 text-uppercase"
      variant="outline-secondary"
      @click="setDatepickerFiltersToCurrentMonth"
      >{{ $t('datetime.month.current-short') }}</b-button
    >
    <b-button
      v-if="showLastMonth"
      size="xs"
      class="text-uppercase"
      variant="outline-secondary"
      @click="setDatepickerFiltersToLastMonth"
      >{{ $t('datetime.month.last-short') }}</b-button
    >
  </div>
</template>

<script>
import { LocalDate } from '@/util/LocalDateTimeFormatter'

export default {
  name: 'DatepickerQuickfilter',
  props: {
    showToday: {
      required: false,
      type: Boolean,
      default: true
    },
    showYesterday: {
      required: false,
      type: Boolean,
      default: true
    },
    showCurrentWeek: {
      required: false,
      type: Boolean,
      default: true
    },
    showLastWeek: {
      required: false,
      type: Boolean,
      default: true
    },
    showCurrentMonth: {
      required: false,
      type: Boolean,
      default: true
    },
    showLastMonth: {
      required: false,
      type: Boolean,
      default: true
    }
  },
  methods: {
    setDatepickerFiltersToToday() {
      this.$emit('datePickStart', LocalDate.today())
      this.$emit('datePickEnd', LocalDate.today())
    },
    setDatepickerFiltersToYesterday() {
      this.$emit('datePickStart', LocalDate.yesterday())
      this.$emit('datePickEnd', LocalDate.yesterday())
    },
    setDatepickerFiltersToCurrentWeek() {
      this.$emit('datePickStart', LocalDate.mondayInCurrentWeek())
      this.$emit('datePickEnd', LocalDate.fridayInCurrentWeek())
    },
    setDatepickerFiltersToLastWeek() {
      this.$emit('datePickStart', LocalDate.mondayInLastWeek())
      this.$emit('datePickEnd', LocalDate.fridayInLastWeek())
    },
    setDatepickerFiltersToCurrentMonth() {
      this.$emit('datePickStart', LocalDate.firstDayOfCurrentMonth())
      this.$emit('datePickEnd', LocalDate.lastDayOfCurrentMonth())
    },
    setDatepickerFiltersToLastMonth() {
      this.$emit('datePickStart', LocalDate.firstDayOfLastMonth())
      this.$emit('datePickEnd', LocalDate.lastDayOfLastMonth())
    }
  }
}
</script>
